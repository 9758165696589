export const data = {
  getAllItems() {
    const items = []
    this.options.forEach((option) => items.push(...option.items))
    return items
  },



  



  options: [
    {
      id: 1,
      title: 'Authoritive',
      items: [
        {
          id: 1,
          name: 'Not Mutable',
          desc: ''
        },
        {
          id: 2,
          name: 'Not Mintable',
          desc: ''
        },
        {
          id: 3,
          name: 'Not Freezeable',
          desc: ''
        }
      ]
    },



    {
      id: 2,
      title: 'Initial Liquidity',
      items: [

        {
          id: 9,
          name: 'at least 25 SOL',
          desc: ''
        },

        {
          id: 21,
          name: 'at least 50 SOL',
          desc: ''
        },

        {
          id: 22,
          name: 'at least 100 SOL',
          desc: ''
        },

        {
          id: 23,
          name: 'at least 250 SOL',
          desc: ''
        },

      ]
    },
   
       {
      id: 3,
      title: 'Pair Age',
      items: [

        {
          id: 39,
          name: 'under 5 minutes',
          desc: ''
        },

        {
          id: 40,
          name: 'under 10 minutes',
          desc: ''
        },

        {
          id: 41,
          name: 'under 15 minutes',
          desc: ''
        },

        {
          id: 42,
          name: 'under 30 minutes',
          desc: ''
        },

        {
          id: 43,
          name: 'under 1 hour',
          desc: ''
        },

      ]
    },

    {
      id: 4,
      title: 'Liquidity',
      items: [
        {
          id: 18,
          name: 'Burned',
          desc: ''
        },
      ]
    },


    {
      id: 5,
      title: 'Socials Exist',
      items: [
        {
          id: 4,
          name: 'Telegram',
          desc: ''
        },
        {
          id: 5,
          name: 'Twitter',
          desc: ''
        },
        {
          id: 6,
          name: 'Website',
          desc: ''
        },
        {
          id: 45,
          name: 'TikTok',
          desc: ''
        }
      ]
    },



    {
      id: 7,
      title: 'Volume Spike (SOL)',
      items: [

        {
          id: 24,
          name: 'At least 50 SOL',
          desc: ''
        },

        {
          id: 25,
          name: 'At least 100 SOL',
          desc: ''
        },

        {
          id: 26,
          name: 'At least 150 SOL',
          desc: ''
        },

        {
          id: 27,
          name: 'At least 200 SOL',
          desc: ''
        },

        {
          id: 28,
          name: 'At least 250 SOL',
          desc: ''
        },

          {
          id: 29,
          name: 'At least 500 SOL',
          desc: ''
        },


          {
          id: 30,
          name: 'At least 1000 SOL',
          desc: ''
        },    

      ]
    },


    {
      id: 8,
      title: 'Volume Spike (Buys)',
      items: [

        {
          id: 31,
          name: 'At least 10 buys',
          desc: ''
        },


          {
          id: 32,
          name: 'At least 20 buys',
          desc: ''
        },       


        {
          id: 33,
          name: 'At least 30 buys',
          desc: ''
        },


          {
          id: 34,
          name: 'At least 50 buys',
          desc: ''
        },     

  

      ]
    },


    {
      id: 9,
      title: 'Pump Fun',
      items: [

        {
          id: 10,
          name: 'Pump Fun Tokens Only',
          desc: ''
        },

        {
          id: 35,
          name: 'Bonding under 5 minutes',
          desc: ''
        },

          {
          id: 36,
          name: 'Bonding under 10 minutes',
          desc: ''
        },

            {
          id: 37,
          name: 'Bonding under 15 minutes',
          desc: ''
        },
       




      ]
    },



    {
      id: 8,
      title: 'Exclusions',
      items: [

 



                {
          id: 49,
          name: 'Exclude Bundled Launches',
          desc: ''
        }


      ]
    },



  ]
}





/*

    {
      id: 5,
      title: 'Dextools',
      items: [



        {
          id: 17,
          name: 'Fast Tracked',
          desc: ''
        },

                {
          id: 20,
          name: 'Hot Pairs',
          desc: ''
        },


      ]
    },
    
    1:  { signal_mutable: true },
    2:  { signal_mintable: true },
    3:  { signal_freezeable: true },
    4:  { signal_telegram: true },
    5:  { signal_twitter: true },
    6:  { signal_website: true },
    7:  { signal_initial_liquidity: 1 },
    8:  { signal_initial_liquidity: 5 },
    9: { signal_initial_liquidity: 25 },
    10: { signal_pump: true },
    11: { signal_moonshot: true },

        12: { signal_distro_top5: 10 },
    13: { signal_distro_top10: 10 },
    14: { signal_distro_holders: 10 },
    15: { signal_distro_owner: 10 },


        16: { signal_trending: true },
    17: { signal_dextools: true },
    18: { signal_burned: true }
    */
